@import "../../scss/variables";

.edit-element {
    position: relative;

    &.position-above {
        .edit-element-toolbar {
            top: -2.5rem;
        }
    }
}

.edit-element-toolbar, .edit-element-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

.edit-element-toolbar {
    z-index: 2;
    display: none;
    padding: .5rem;
    background-color: $gray-900;
    color: #fff;
    opacity: 0.8;
}

.edit-element-overlay {
    z-index: 1;
    bottom: 0;
}

.edit-element:hover, .edit-element.is-active, .edit-element.is-editing {
    .edit-element-toolbar {
        display: block;
    }

    .edit-element-overlay {
        border: 1px solid $gray-900;
    }
}

.empty-content-zone {
    background-color: $gray-100;
    border: 1px solid $gray-200;
    padding: 1rem;
    text-align: center;
}