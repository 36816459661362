@import "../../scss/variables";

.swatch {
    width: 3rem;
    height: 3rem;
    border-radius: $border-radius;
    border: 3px solid #fff;
    box-shadow: 0 0 0 $input-border-width $input-border-color;
    cursor: pointer;
  }
  
.popover {
    position: absolute;
    z-index: $zindex-popover;
    top: calc(100% + 2px);
    right: 0;
    border-radius: $border-radius;
}
