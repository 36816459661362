$booking: #a83333;
$contact: #0000cc;
$membership: #56c615;

.bg-booking { 
    background-color: $booking;
}

.bg-contact { 
    background-color: $contact;
}

.bg-membership {
    background-color: $membership;
}