@import "../../../scss/variables";

.print-transform-layout {
    display: inline-block;
    border: 1px solid $gray-200;
    background-size: cover;

    > div {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        border-color: $gray-400;
        border-style: solid;
    }

    img {
        margin: 0;
        max-width: 100%;
        max-height: 100%; 
    }
}

.print-transform-field {
    position: absolute;
    transform-origin: left top;
    
    .edit-element, .edit-element > p {
        margin: 0;
        width: 100%;
        height: 100%;
        line-height: normal;
        overflow: hidden;
    }

    img {
        width: 100%;
        height: 100%;
    }
    p {
        overflow: hidden;
    }
}

.design-section-stage:not(.is-editing) .edit-element:hover .print-transform-field.allow-edit
, .edit-element.is-active .print-transform-field.allow-edit
, .edit-element.is-editing .print-transform-field.allow-edit {
    outline: 1px solid $info;
    cursor: pointer;
}