@import "../../../scss/variables";

.drag-indicators {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-dropdown;
}

.drag-shadow {
    position: absolute;
    background-color: black;
    opacity: 0.3;
}

.drag-shadow-horizontal {
    top: 0;
    height: 10mm;
}

.drag-shadow-vertical {
    left: 0;
    width: 10mm;
}

.drag-grid-line {
    position: absolute;
    border-color: $gray-200;
    border-style: dashed;
}

.drag-grid-line-left {
    top: 10mm;
    bottom: 0;
    border-left-width: 1mm;
}

.drag-grid-line-right {
    top: 10mm;
    bottom: 0;
    border-right-width: 1mm;
}

.drag-grid-line-top {
    left: 10mm;
    right: 0;
    border-top-width: 1mm;
}

.drag-grid-line-bottom {
    left: 10mm;
    right: 0;
    border-bottom-width: 1mm;
}
