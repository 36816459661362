.crmattribute {
	background-color: yellow;
}

.cursor-move {
    cursor: move;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-zoom-in {
    cursor: zoom-in;
}

.currency-input {
    max-width: 7rem;

    .input-group-text { 
        background-color: transparent;
        padding-left: .5rem;
        padding-right: .5rem;
    }

    .form-control {
        padding-left: .5rem;
    }
}

.min-vh-25 {
    min-height: 25vh !important;
}

.min-vh-50 {
    min-height: 50vh !important;
}

.min-vh-75 {
    min-height: 75vh !important;
}

.min-h-100 {
    min-height: 100%;
}