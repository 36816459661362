/**********************************************
* Ink v1.0.5 - Copyright 2013 ZURB Inc        *
**********************************************/

/* Client-specific Styles & Reset */
#outlook a { 
  padding:0; 
} 

html.email-html {
    height: 100%;
}

body, #ink {
  width: 100% !important; 
  height: 100%;
  min-width: 100%;
  -webkit-text-size-adjust:100%; 
  -ms-text-size-adjust:100%; 
  margin:0; 
  padding:0;
}

#ink .ExternalClass { 
  width:100%;
} 

#ink .ExternalClass, 
#ink .ExternalClass p, 
#ink .ExternalClass span, 
#ink .ExternalClass font, 
#ink .ExternalClass td, 
#ink .ExternalClass div { 
  line-height: 100%; 
} 

#backgroundTable { 
  margin:0; 
  padding:0; 
  width:100% !important; 
  line-height: 100% !important; 
}

#ink img { 
  outline:none; 
  text-decoration:none; 
  -ms-interpolation-mode: bicubic;
  width: 100%;
  max-width: 100%; 
  float: left;
  clear: both; 
  display: block;
}

#ink img.left {
    float: left;
}
#ink img.center {
    float: none;
    display: inline-block;
}
#ink img.right {
    float: right;
}

#ink center {
  width: 100%;
  min-width: 576px;
}

#ink a img { 
  border: none;
}

#ink table {
  border-spacing: 0;
  border-collapse: collapse;
  mso-table-lspace: 0pt; 
  mso-table-rspace: 0pt;
}

#ink td { 
  word-break: break-word;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  hyphens: none;
  border-collapse: collapse !important; 
}

#ink table, #ink tr, #ink td {
  padding: 0;
  vertical-align: top;
  text-align: left;
}

#ink hr {
  color: #d9d9d9; 
  background-color: #d9d9d9; 
  height: 1px; 
  border: none;
}

/* Responsive Grid */
#ink table.container {
  width: 576px;
  margin: 0 auto;
  text-align: inherit;
}

#ink table.row { 
  padding: 0px; 
  width: 100%;
  position: relative;
}

#ink table.container table.row {
  display: block;
}

#ink td.wrapper {
  padding: 10px 20px 0px 0px;
  position: relative;
}

#ink table.columns,
#ink table.column {
  margin: 0 auto;
}

#ink table.columns td,
#ink table.column td {
  padding: 0px 0px 10px; 
}

#ink table.columns td.sub-columns,
#ink table.column td.sub-columns,
#ink table.columns td.sub-column,
#ink table.column td.sub-column {
  padding-right: 10px;
}

#ink td.sub-column, #ink td.sub-columns {
  min-width: 0px;
}

#ink table.row td.last,
#ink table.container td.last {
  padding-right: 0px;
}

#ink table.one { width: 26px; }
#ink table.two { width: 76px; }
#ink table.three { width: 126px; }
#ink table.four { width: 176px; }
#ink table.five { width: 226px; }
#ink table.six { width: 276px; }
#ink table.seven { width: 326px; }
#ink table.eight { width: 376px; }
#ink table.nine { width: 426px; }
#ink table.ten { width: 474px; }
#ink table.eleven { width: 526px; }
#ink table.twelve { width: 576px; }

#ink table.one center { min-width: 26px; }
#ink table.two center { min-width: 76px; }
#ink table.three center { min-width: 126px; }
#ink table.four center { min-width: 176px; }
#ink table.five center { min-width: 226px; }
#ink table.six center { min-width: 276px; }
#ink table.seven center { min-width: 326px; }
#ink table.eight center { min-width: 376px; }
#ink table.nine center { min-width: 426px; }
#ink table.ten center { min-width: 476px; }
#ink table.eleven center { min-width: 526px; }
#ink table.twelve center { min-width: 576px; }

#ink table.one .panel center { min-width: 6px; }
#ink table.two .panel center { min-width: 56px; }
#ink table.three .panel center { min-width: 106px; }
#ink table.four .panel center { min-width: 156px; }
#ink table.five .panel center { min-width: 206px; }
#ink table.six .panel center { min-width: 256px; }
#ink table.seven .panel center { min-width: 306px; }
#ink table.eight .panel center { min-width: 356px; }
#ink table.nine .panel center { min-width: 406px; }
#ink table.ten .panel center { min-width: 456px; }
#ink table.eleven .panel center { min-width: 506px; }
#ink table.twelve .panel center { min-width: 556px; }

#ink .columns td.one,
#ink .column td.one { width: 8.333333%; }
#ink .columns td.two,
#ink .column td.two { width: 16.666666%; }
#ink .columns td.three,
#ink .column td.three { width: 25%; }
#ink .columns td.four,
#ink .column td.four { width: 33.333333%; }
#ink .columns td.five,
#ink .column td.five { width: 41.666666%; }
#ink .columns td.six,
#ink .column td.six { width: 50%; }
#ink .columns td.seven,
#ink .column td.seven { width: 58.333333%; }
#ink .columns td.eight,
#ink .column td.eight { width: 66.666666%; }
#ink .columns td.nine,
#ink .column td.nine { width: 75%; }
#ink .columns td.ten,
#ink .column td.ten { width: 83.333333%; }
#ink .columns td.eleven,
#ink .column td.eleven { width: 91.666666%; }
#ink .columns td.twelve,
#ink .column td.twelve { width: 100%; }

#ink td.offset-by-one { padding-left: 46px; }
#ink td.offset-by-two { padding-left: 96px; }
#ink td.offset-by-three { padding-left: 146px; }
#ink td.offset-by-four { padding-left: 196px; }
#ink td.offset-by-five { padding-left: 246px; }
#ink td.offset-by-six { padding-left: 296px; }
#ink td.offset-by-seven { padding-left: 346px; }
#ink td.offset-by-eight { padding-left: 396px; }
#ink td.offset-by-nine { padding-left: 446px; }
#ink td.offset-by-ten { padding-left: 496px; }
#ink td.offset-by-eleven { padding-left: 546px; }

#ink td.expander {
  visibility: hidden;
  width: 0px;
  padding: 0 !important;
}

#ink table.columns .text-pad,
#ink table.column .text-pad {
  padding-left: 10px;
  padding-right: 10px;
}

#ink table.columns .left-text-pad,
#ink table.columns .text-pad-left,
#ink table.column .left-text-pad,
#ink table.column .text-pad-left {
  padding-left: 10px;
}

#ink table.columns .right-text-pad,
#ink table.columns .text-pad-right,
#ink table.column .right-text-pad,
#ink table.column .text-pad-right {
  padding-right: 10px;
}

/* Block Grid */
#ink .block-grid {
  width: 100%;
  max-width: 576px;
}

#ink .block-grid td {
  display: inline-block;
  padding:10px;
}

#ink .one-up td {
  width: 570px;
}

#ink .two-up td {
  width:270px;
}

#ink .three-up td {
  width:173px;
}

#ink .four-up td {
  width:125px;
}

#ink .five-up td {
  width:96px;
}

#ink .six-up td {
  width:76px;
}

#ink .seven-up td {
  width:62px;
}

#ink .eight-up td {
  width:52px;
}

/* Alignment & Visibility Classes */

#ink table.center, #ink td.center {
  text-align: center;
}

#ink h1.center,
#ink h2.center,
#ink h3.center,
#ink h4.center,
#ink h5.center,
#ink h6.center {
  text-align: center;
}

#ink span.center {
  display: block;
  width: 100%;
  text-align: center;
}

#ink img.center {
  margin: 0 auto;
  float: none;
}

#ink .show-for-small,
#ink .hide-for-desktop {
  display: none;
  mso-hide: all;
  width: 0; /* Gmail */
  max-height: 0px; /* Gmail */
  overflow: hidden;
}

.hide-on-designer {
    display: none;
}

/* Typography */

#ink p {
    color: inherit;
    font-weight: inherit;    
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    text-align: inherit;
}

#ink, #ink td 
{ 
    padding:0; 
    margin: 0;
    text-align: left; 
}

#ink h1, #ink h2, #ink h3, #ink h4, #ink h5, #ink h6 {
  word-break: normal;
}

#ink h1 {font-size: 40px;}
#ink h2 {font-size: 36px;}
#ink h3 {font-size: 32px;}
#ink h4 {font-size: 28px;}
#ink h5 {font-size: 24px;}
#ink h6 {font-size: 20px;}
#ink, #ink td {font-size: 14px;line-height:19px;}

#ink p.lead, #ink p.lede, #ink p.leed {
  font-size: 18px;
  line-height:21px;
}

#ink p { 
  margin-top: 5px;
  margin-bottom: 5px;
}

#ink small {
  font-size: 10px;
}

#ink a {
  text-decoration: none;
  color: inherit;
}

/* Panels */

#ink .panel {
  background: #f2f2f2;
  border: 1px solid #d9d9d9;
  padding: 10px !important;
}

#ink .sub-grid table {
  width: 100%;
}

#ink .sub-grid td.sub-columns {
  padding-bottom: 0;
}

/* Buttons */

#ink table.button,
#ink table.tiny-button,
#ink table.small-button,
#ink table.medium-button,
#ink table.large-button {
  width: 100%;
  overflow: hidden;
}

#ink table.button td,
#ink table.tiny-button td,
#ink table.small-button td,
#ink table.medium-button td,
#ink table.large-button td {
  display: block;
  width: auto !important;
  text-align: center;
  background: #2ba6cb;
  border: 1px solid #2284a1;
  color: #ffffff;
  padding: 8px 0;
}

#ink table.tiny-button td {
  padding: 5px 0 4px;
}

#ink table.small-button td {
  padding: 8px 0 7px;
}

#ink table.medium-button td {
  padding: 12px 0 10px;
}

#ink table.large-button td {
  padding: 21px 0 18px;
}

#ink table.button td a,
#ink table.tiny-button td a,
#ink table.small-button td a,
#ink table.medium-button td a,
#ink table.large-button td a {
  font-weight: bold;
  text-decoration: none;
  font-family: Arial, sans-serif;
  color: #ffffff;
  font-size: 16px;
}

#ink table.tiny-button td a {
  font-size: 12px;
  font-weight: normal;
}

#ink table.small-button td a {
  font-size: 16px;
}

#ink table.medium-button td a {
  font-size: 20px;
}

#ink table.large-button td a {
  font-size: 24px;
}

#ink table.button:hover td,
#ink table.button:visited td,
#ink table.button:active td {
  background: #2795b6 !important;
}

#ink table.button:hover td a,
#ink table.button:visited td a,
#ink table.button:active td a {
  color: #fff !important;
}

#ink table.button:hover td,
#ink table.tiny-button:hover td,
#ink table.small-button:hover td,
#ink table.medium-button:hover td,
#ink table.large-button:hover td {
  background: #2795b6 !important;
}

#ink table.button:hover td a,
#ink table.button:active td a,
#ink table.button td a:visited,
#ink table.tiny-button:hover td a,
#ink table.tiny-button:active td a,
#ink table.tiny-button td a:visited,
#ink table.small-button:hover td a,
#ink table.small-button:active td a,
#ink table.small-button td a:visited,
#ink table.medium-button:hover td a,
#ink table.medium-button:active td a,
#ink table.medium-button td a:visited,
#ink table.large-button:hover td a,
#ink table.large-button:active td a,
#ink table.large-button td a:visited {
  color: #ffffff !important; 
}

#ink table.secondary td {
  background: #e9e9e9;
  border-color: #d0d0d0;
  color: #555;
}

#ink table.secondary td a {
  color: #555;
}

#ink table.secondary:hover td {
  background: #d0d0d0 !important;
  color: #555;
}

#ink table.secondary:hover td a,
#ink table.secondary td a:visited,
#ink table.secondary:active td a {
  color: #555 !important;
}

#ink table.success td {
  background: #5da423;
  border-color: #457a1a;
}

#ink table.success:hover td {
  background: #457a1a !important;
}

#ink table.alert td {
  background: #c60f13;
  border-color: #970b0e;
}

#ink table.alert:hover td {
  background: #970b0e !important;
}

#ink table.radius td {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#ink table.round td {
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
}

/* Outlook First */

body.outlook p {
  display: inline !important;
}

/*  Media Queries */

@media only screen and (max-width: 600px) {
    table.email-body {
        width: 100% !important;
    }

    #ink img {
        width: 100% !important;
        height: auto !important;
    }

    #ink center {
        min-width: 0 !important;
    }

    #ink .container {
        width: 95% !important;
    }

    #ink .row {
        width: 100% !important;
        display: block !important;
    }

    #ink .wrapper {
        display: block !important;
        padding-right: 0 !important;
    }

    #ink .columns,
    #ink .column {
        table-layout: fixed !important;
        float: none !important;
        width: 100% !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
        display: block !important;
    }
    #ink table.columns, #ink table.column
    {
        display: table !important;
    }

    #ink .wrapper.first .columns,
    #ink .wrapper.first .column {
        display: table !important;
    }

    #ink table.columns td:not(.half-width, .thirty-width, .seventy-width, .one, .two, .three, .four, .five, .six, .seven, .eight, .nine, .ten, .eleven, .twelve),
    #ink table.column td:not(.half-width, .thirty-width, .seventy-width, .one, .two, .three, .four, .five, .six, .seven, .eight, .nine, .ten, .eleven, .twelve) {
        width: 100% !important;
    }

    #ink .columns td.one,
    #ink .column td.one { width: 8.333333% !important; }
    #ink .columns td.two,
    #ink .column td.two { width: 16.666666% !important; }
    #ink .columns td.three,
    #ink .column td.three { width: 25% !important; }
    #ink .columns td.four,
    #ink .column td.four { width: 33.333333% !important; }
    #ink .columns td.five,
    #ink .column td.five { width: 41.666666% !important; }
    #ink .columns td.six,
    #ink .column td.six { width: 50% !important; }
    #ink .columns td.seven,
    #ink .column td.seven { width: 58.333333% !important; }
    #ink .columns td.eight,
    #ink .column td.eight { width: 66.666666% !important; }
    #ink .columns td.nine,
    #ink .column td.nine { width: 75% !important; }
    #ink .columns td.ten,
    #ink .column td.ten { width: 83.333333% !important; }
    #ink .columns td.eleven,
    #ink .column td.eleven { width: 91.666666% !important; }
    #ink .columns td.twelve,
    #ink .column td.twelve { width: 100% !important; }

    #ink table.columns td.width-auto { width: auto !important; }

    #ink td.offset-by-one,
    #ink td.offset-by-two,
    #ink td.offset-by-three,
    #ink td.offset-by-four,
    #ink td.offset-by-five,
    #ink td.offset-by-six,
    #ink td.offset-by-seven,
    #ink td.offset-by-eight,
    #ink td.offset-by-nine,
    #ink td.offset-by-ten,
    #ink td.offset-by-eleven {
        padding-left: 0 !important;
    }

    #ink table.columns td.expander {
        width: 1px !important;
    }

    #ink .right-text-pad,
    #ink .text-pad-right {
        padding-left: 10px !important;
    }

    #ink .left-text-pad,
    #ink .text-pad-left {
        padding-right: 10px !important;
    }

    #ink .hide-for-small,
    #ink .show-for-desktop {
        display: none !important;
    }

    #ink .show-for-small,
    #ink .hide-for-desktop {
        display: initial !important;
        width: auto !important; /* Gmail */
        max-height: inherit !important; /* Gmail */
        overflow: visible !important;
    }

    #ink div.show-for-small,
    #ink div.hide-for-desktop {
        display: block !important;
    }

    #ink table.show-for-small,
    #ink table.hide-for-desktop {
        display: table !important;
    }

    #ink p.show-for-small,
    #ink p.hide-for-desktop {
        display: block !important;
    }

    #ink span.show-for-small,
    #ink span.hide-for-desktop {
        display: inline !important;
    }

    #ink .show-for-small.full-width {
      width: 100% !important;
    }
}

/* Custom */
#ink table {
    background-color: transparent;
}

#ink table.content-table {
    margin: 0 0;
    padding: 0 0;
    border-spacing: 0 0;
    position: relative;
    border-collapse: collapse;
}

#ink table.content-table.twelve {
    width: 576px;
}

#ink table.content-table.six, #ink table.content-table td.six {
    width: 288px;
}

#ink table.content-table.four, #ink table.content-table td.four {
    width: 192px;
}

#ink table.content-table.columns td, #ink table.content-table.column td {
    padding: 0 0;
}

#ink .zero-margin {
    margin: 0 auto;
}

#ink .full-width {
    width: 100%;
}

#ink .half-width,
#ink table.columns td.half-width, #ink table.column td.half-width {
    width: 50%;
}

#ink .thirty-width,
#ink table.columns td.thirty-width, #ink table.column td.thirty-width {
    width: 30%;
}

#ink .seventy-width,
#ink table.columns td.seventy-width, #ink table.column td.seventy-width {
    width: 70%;
}

#ink table.columns table.mobile-full, #ink table.column table.mobile-full,
#ink table.columns td.mobile-full, #ink table.column td.mobile-full {
    width: 50%;
}

#ink .mobile-full2,
#ink table.columns td.mobile-full2, #ink table.column td.mobile-full2 {
    width: 48%;
}

#ink .margin-left { margin-left: 5px; }

@media only screen and (min-width: 601px) {
    #ink .half-width,
    #ink table.columns td.half-width, #ink table.column td.half-width {
        width: 50% !important;
    }

    #ink .thirty-width,
    #ink table.columns td.thirty-width, #ink table.column td.thirty-width {
        width: 30% !important;
    }

    #ink .seventy-width,
    #ink table.columns td.seventy-width, #ink table.column td.seventy-width {
        width: 70% !important;
    }

    #ink .margin-left { 
        margin-left: 15px !important;
    }
}

@media only screen and (max-width: 600px) {
    #ink .full,
    #ink table.columns td.full,
    #ink table.column td.full,
    #ink table.columns table.mobile-full,
    #ink table.column table.mobile-full,
    #ink table.columns td.mobile-full,
    #ink table.column td.mobile-full,
    #ink .full.half-width,
    #ink table.columns td.full.half-width,
    #ink table.column td.full.half-width,
    #ink .full.thirty-width,
    #ink table.columns td.full.thirty-width,
    #ink table.column td.full.thirty-width,
    #ink .full.seventy-width,
    #ink table.columns td.full.seventy-width,
    #ink table.column td.full.seventy-width {
      display: block;
      width: 100% !important;
    }

    #ink table.columns table.mobile-full {
      display: table !important;
    }

    #ink .half-width,
    #ink table.columns td.half-width, #ink table.column td.half-width {
        width: 50% !important;
    }

    #ink .thirty-width,
    #ink table.columns td.thirty-width, #ink table.column td.thirty-width {
        width: 30% !important;
    }

    #ink .seventy-width,
    #ink table.columns td.seventy-width, #ink table.column td.seventy-width {
        width: 70% !important;
    }

    #ink .mobile-full2,
    #ink table.columns td.mobile-full2, 
    #ink table.column td.mobile-full2 {
        display: block;
        width: 100% !important;
    }

    #ink table.columns td.half-stack, #ink table.column td.half-stack {
        float: left;
        display: inline-block;
        width: 50% !important;
    }
}

/* Campaign Response */
#campaign-response-container {
    position: fixed;
    top: 0; left:0; right: 0; bottom: 0;
    background: rgba(34, 34, 34, 0.8);
    z-index: 99999;
    display: table-cell;
    vertical-align: middle;
}

#campaign-response {
    box-sizing: border-box;
    min-width: 300px; max-width: 480px; width: 100%; 
    margin: 110px auto 0 auto;
    background: #fff;
    padding: 20px 20px;
            
    -webkit-box-shadow: 0px 0px 24px 8px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 24px 8px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 24px 8px rgba(0,0,0,0.75);

    position: relative;
    /*top: 110px;*/
}

#campaign-response #close-campaign-response {
    position: absolute; right:28px; top: 15px;
    font-weight: bold; font-size: 28px;
}

#campaign-response #close-campaign-response a {
    text-decoration: none; color: inherit;
}

#campaign-response textarea, #campaign-response select, #campaign-response button, #campaign-response table {
    width: 100%;
    font-family: Arial, sans-serif;
}

#campaign-response #submit-container {
    text-align: center;
}

#campaign-response button {
    width: 50%;
}

@media only screen and (max-width: 600px) {
    #campaign-response {
        max-width: 280px;
        min-width: 150px;
    }

    #campaign-response button
    {
        width: 100%;
    }
}

#ink .social-media-icon {
  width: 48px !important;
  height: 48px !important;
}