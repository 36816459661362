@import "../../../scss/variables";

#heat-map-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.active {
        background-color: rgba(255,255,255,0.6);
    }
}

.heat-map-anchor { 
    position: absolute;

    &.heat-0 {
        background-color: rgba(130,210,255,0.5);
    }

    &.heat-1 {
        background-color: rgba(255,255,0,0.5);
    }

    &.heat-2 {
        background-color: rgba(255,165,0,0.5);
    }

    &.heat-3 {
        background-color: rgba(255,60,30,0.5);
    }
}

.heat-map-item {
    position: absolute;
}

.heat-map-item-percentage {
    position: relative;
    float: left;
    background-color: $white;
    border: 1px solid $gray-200;
    padding: .2rem;
    z-index: $zindex-popover;
}

.heat-map-item-details {
    display: none;
    position: relative;
    float: left;
    background-color: $white;
    border: 1px solid $gray-200;
    padding: .5rem;
    z-index: $zindex-tooltip;
}

.heat-map-anchor:hover + .heat-map-item { 
    > .heat-map-item-details {
        display: block;
    }

    > .heat-map-item-percentage {
        background-color: $black;
        color: $white;
        border-color: $black;
    }
}