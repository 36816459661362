.vertical-rule {
    display: inline-block;

    > svg {
        width: 10mm;

        > text  {
            font-size: 3.5mm;
            writing-mode: tb;
        }

        > line {
            stroke: black;
            stroke-width: 0.1mm;
        }
    }
}