@import "../../scss/variables";

#form-page-header {
    background-color: $gray-100;
    border-bottom: 1px solid $gray-200;
    padding: 1rem 2rem;

    h2 {
        display: inline;
        margin-bottom: 0;
    }
}