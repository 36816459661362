@import "../scss/variables.scss";
@import "~bootstrap/scss/mixins";

body {
    overflow: hidden;
}

main {
    display: flex;
    align-items: stretch;
    min-height: 100vh;
    margin-top: -1 * $nav-link-height;
    padding-top: $nav-link-height;
}

#content {
    position: relative;
    flex: 1 1 auto;
    overflow-y: scroll;
    height: calc(100vh - $nav-link-height);

    &.has-no-scrollbar {
        padding-right: 2.5rem;
    }
}