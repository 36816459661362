@import "../../scss/variables";

.date-picker-wrapper {
    &.date-only {
        max-width: 130px;
    }
    
    &.is-invalid .invalid-feedback {
        display: block;
    }
}

.form-control.is-invalid.date-validation,
.form-control.is-valid.date-validation {
    background-image: none !important;
}

.react-datepicker-popper {
    z-index: $zindex-dropdown;
}