@import "../../scss/variables";

$circle-height: 40px;
$stage-number-height: 32px;
$stage-name-width-thin: 100px;
$stage-name-width: 140px;
$stage-divider-width-thin: $stage-name-width-thin - 20px;
$stage-divider-width: $stage-name-width - 20px;
$bar-height: 6px;

.progress-tracker {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    margin-bottom: .5rem;

    .stage {
        float: left;
        z-index: 110;

        .stage-number-wrapper {
            width: $circle-height;
            height: $circle-height;
            background-color: $white;
            border-radius: $circle-height;
            border: 1px solid $gray-300;  
        }

        .stage-number {
            display: inline-block;
            width: $stage-number-height;
            line-height: $stage-number-height;
            border-radius: $stage-number-height;
            margin: calc(($circle-height - $stage-number-height - 2px) / 2);
            color: $gray-300;
        }

        .stage-name {
            display: block;
            color: $gray-300;
            font-size: $font-size-sm;
        }

        .stage-name-width-normal {
            width: $stage-name-width;
            margin-left: calc((($stage-name-width - $circle-height) / 2) * -1);
        }

        .stage-name-width-thin {
            width: $stage-name-width-thin;
            margin-left: calc((($stage-name-width-thin - $circle-height) / 2) * -1);
        }

        &.done {
            cursor: pointer;

            .stage-number {
                color: $white;
                background-color: $green;
            }

            .stage-name {
                color: $gray-600;
            }
        }

        &.current {
            .stage-number {
                color: $white;
                background-color: $blue;
            }

            .stage-name {
                color: $blue;
            }
        }
    }

    .stage-divider  {
        height: $bar-height;
        margin-top: calc(($circle-height - $bar-height - 2px) / 2);
        margin-left: $circle-height - 5;
        margin-right: -5px;
        background-color: $white;
        border-top: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;
        z-index: 100;

        &.done {
            background-color: $gray-200;
        }
    }

    .stage-divider-width-normal {
        width: $stage-divider-width;
    }

    .stage-divider-width-thin {
        width: $stage-divider-width-thin;
    }
}