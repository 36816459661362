#drag-overlay {
    height: 2rem;
}

.droppable-element {
    position: relative;
    padding: 2px 0;
    cursor: pointer;
    text-align: center;

    &.is-over {
        outline: 1px solid $green;
        background-color: $green;
        color: $white;  
    }
}

.is-dragging {
    .edit-element.is-active {
        z-index: $zindex-tooltip;
        opacity: 0.7;
    }
 
    .droppable-element {
        &.is-over {
            outline: 1px solid $green;
            background-color: $green;
        }
    }
}
