.horizontal-rule {
    margin-left: 10mm;

    > svg {
        height: 10mm;

        > text  {
            font-size: 3.5mm;
        }

        > line {
            stroke: black;
            stroke-width: 0.1mm;
        }
    }
}