button.btn-primary, a.btn-primary {
    color: #fff;

    &:disabled,
    &:hover,
    &:active,
    &:focus-visible {
        color: #fff !important;
    }
}
