@import "../../scss/variables";

.editor {
    z-index: 1000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem 1.5rem;
    background-color: $body-bg;
    animation: slideFromRight 0.5s forwards;
    overflow-y: auto;
}

@keyframes slideFromRight {
    0% {
        right: -100%; /* Start off the screen */
    }
    100% {
        right: 0; /* Slide into view */
    }
}
