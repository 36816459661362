@import "../../scss/variables";

.form-label-spacer {
    height: $input-line-height * 1rem;
    margin-bottom: $form-label-margin-bottom;
}

.form-check-input {
    cursor: pointer;
}

.form-check:not(.form-check-inline) {
    margin-top: $input-padding-y;

    .form-check-label {
        margin-bottom: $input-padding-y;
    }
}