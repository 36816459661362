@import "../../scss/variables.scss";

#header {
    background-color: $gray-200;
    color: $body-color;
    margin-left: 14rem;

    &.test-slot {
        background-color: lighten($warning, 20%);
    }

    .navbar-brand {
        width: 14rem;

        img {
            margin-top: -1rem;
            margin-bottom: -1rem;
        }
    }
}