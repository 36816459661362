@import "../../scss/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/modal";

@mixin modal-variant($background, $border, $color) {
    .modal-content {
        color: $color;
        background: $background;
        border-color: $border;
    }

    .modal-header {
        border-bottom-color: $background;
    }
}

@each $color, $value in $theme-colors {
    .modal-#{$color} {
        @include modal-variant(
            theme-color-level($color, $alert-bg-level),
            theme-color-level($color, $alert-border-level),
            theme-color-level($color, $alert-color-level)
        );
    }
}

.modal-small {
    .modal-dialog-centered {
        .modal-content {
            margin: 0 10%;
            .modal-header {
                padding-bottom: 0;
                .modal-title {
                    font-size: 1.1rem;
                }
            }
        }
    }
}

.modal-backdrop {
    background: rgba($black, 0.8);
}

.modal-footer {
    display: block;
}
