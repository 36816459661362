@import "../../../scss/variables";

.undo-redo {
    position: absolute;
    z-index: $zindex-popover;
    background-color: $white;
    color: $black;
    border: 1px solid $gray-300;

    div {
        display: inline-block;
        padding: .5rem;
        line-height: 1rem;
        cursor: pointer;

        &:hover {
            background-color: $gray-100;
        }

        &.disabled {
            background-color: $gray-200;
        }
    }

    div:first-of-type {
        border-right: 1px solid $gray-200;
    }
}