.svg-background {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #fff;
  -webkit-overflow-scrolling: touch;

  svg {
      position: absolute;
      z-index: 50;
  }

  img {
      position: absolute;
      top: 0;
      left: 0;
      max-width: unset;
  }
}