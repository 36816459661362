@import "../../scss/variables";

.form-control.searchable_select {
    position: relative;
    padding: .1875rem;

    .select__menu {
        z-index: $zindex-modal;
    }
}

.searchable_select:focus-within {
    border-color: $input-btn-focus-color;
    box-shadow: $input-focus-box-shadow;
}

.custom-select {
    div[class$="-control"] {
        border: none;
        box-shadow: none;
    }
}
