.row-label {
    position: absolute;
    width: 4rem;
}

.seat {
    cursor: pointer;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 9px;
}