@import "~bootstrap/scss/functions";
@import "../../../scss/variables";

.product-variant-type-name {
    cursor: pointer;
    display: flex;
    align-items: center;
    
    &::before {
        content: escape-svg($accordion-button-icon);
        display: inline-block;
        width: $accordion-icon-width;
        height: $accordion-icon-width;
        margin-right: $accordion-icon-width;
        transform: rotate(-90deg);
        transition: $accordion-icon-transition;
    }

    &.active::before {
        transform: rotate(0deg);
    }
}

.product-variant {
    background-color: $gray-100;
}