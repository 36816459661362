#tablet-preview-portrait {
	position: relative;
	width: 870px;
	height: 1226px;
	margin: 0 auto;
	background: transparent url('./images/tablet_preview_portrait.png') top left no-repeat;

	transform: scale(0.75);
	transform-origin: 20% 20%;

	-ms-transform: scale(0.75);
	-ms-transform-origin: 20% 20%;
}

#tablet-preview-portrait iframe {
	position: absolute;
	top: 51px;
	left: 51px;
}

#tablet-preview-landscape {
	position: relative;
	height: 870px;
	width: 1226px;
	margin: 0 auto;
	background: transparent url('./images/tablet_preview_landscape.png') top left no-repeat;

	transform: scale(0.75);
	transform-origin: 20% 20%;

	-ms-transform: scale(0.75);
	-ms-transform-origin: 20% 20%;
}

#tablet-preview-landscape iframe {
	position: absolute;
	left: 51px;
	top: 51px;	
}

#mobile-preview-portrait {
	position: relative;
	width: 350px;
	height: 680px;
	margin: 0 auto;
	background: transparent url('./images/mobile_preview_portrait.png') top left no-repeat;

	transform: scale(0.75);
	transform-origin: 20% 20%;

	-ms-transform: scale(0.75);
	-ms-transform-origin: 20% 20%;
}

#mobile-preview-portrait iframe {
	position: absolute;
	top: 90px;
	left: 15px;
}

#mobile-preview-landscape {
	position: relative;
	width: 660px;
	height: 370px;
	margin: 0 auto;
	background: transparent url('./images/mobile_preview_landscape.png') top left no-repeat;

	transform: scale(0.75);
	transform-origin: 20% 20%;

	-ms-transform: scale(0.75);
	-ms-transform-origin: 20% 20%;
}

#mobile-preview-landscape iframe {
	position: absolute;
	top: 25px;
	left: 91px;
}