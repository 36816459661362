@import "../../scss/variables";

.input-group .form-check-inline {
    flex: 0 1 100%;
    margin-right: 0;
}

@media (min-width: map-get($grid-breakpoints, md)) {
    .input-group .form-check-inline {
        flex: 0 1 calc(25% - $form-check-inline-margin-end);
    }
}