@import "../../scss/variables";

.form-table-nav {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid $gray-200;
    padding-bottom: .5rem;
    margin-bottom: 1rem;

    ul.nav span {
        display: inline-block;
        padding: .5rem 1rem;
        cursor: pointer;     
    }
}

.records-per-page {
    margin-right: auto;
    width: 8rem;
}

.table-min-height {
    min-height: 20rem;
}